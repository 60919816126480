<template>
  <div class="home">
    <ys-page-list
        ref="table"
        method="$get"
        :IDs="IDs"
        keyID="2F9D22EF-A093-5053-F676-683D15F43108"
        action="projects/List"
        :params="params"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="投保状态" prop="hasAzx" show>
          <Select v-model="params.hasAzx" transfer clearable placeholder="投保状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所在地区" prop="region" show>
          <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
        <FormItem label="项目阶段" prop="status">
          <Select v-model="params.status" transfer clearable placeholder="项目阶段" style="width: 100px;">
            <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="形象进度" prop="progress">
          <Select v-model="params.progress" transfer clearable placeholder="形象进度" style="width: 100px;">
            <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="工程类型" prop="category" show>
          <Select v-model="params.category" transfer clearable placeholder="工程类型" style="width: 150px;">
            <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="开工日期" prop="startAt">
          <ys-date-picker v-model="params.startAt" style="width: 120px" placeholder="开工日期"></ys-date-picker>
        </FormItem>
        <FormItem label="完工日期" prop="endAt">
          <ys-date-picker v-model="params.endAt" style="width: 120px" placeholder="完工日期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增项目</Button>
          <Button type="primary" @click="putInFlag=true">导入项目</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" title="新增/修改项目">
      <Edit :mid="detailID" @on-ok="editSuccess"></Edit>
    </ys-modal>
    <ys-modal v-model="putInFlag" :width="500" title="导入项目">
      <project-put-in></project-put-in>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import ProjectPutIn from "@/views/projects/list/putIn";
import Detail from "@/views/projects/list/detail.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Detail,
    ProjectPutIn,
    Edit: () => import('./edit.vue')
  },
  data() {
    return {
      params: {
        hasAzx: '',
        region: '',
        countyCode: '',
        cityCode: '',
        category: '',
        status: '',
        startAt: '',
        endAt: '',
        provinceCode: '',
        tag: '',
        progress: '',
        keyword: '',
      },
      putInFlag: false,
      IDs: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 230, title: '项目名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '工程类型', key: 'category', align: 'left', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 110, title: '安监号', key: 'code', align: 'left'},
        {width: 180, title: '施工许可证', key: 'licenseNum', align: 'left'},
        {width: 220, title: '施工单位', key: 'contractName', align: 'left'},
        {minWidth: 150, title: '形象进度', key: 'progress', align: 'left', renderConfig: {type: 'baseData', parentName: '施工阶段'}},
        {width: 230, title: '项目所属区', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 220, title: '承保机构', key: 'policyOrgName', align: 'left'},
        {width: 120, title: '项目阶段', tooltip: true, key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '项目进度'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/projects/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/projects/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    async editSuccess(data, nextType) {
      this.IDs = nextType === 'detail' ? [data.id] : [];
      this.tabName = nextType ? '3' : '1';
      await this.getList(nextType);
    },
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
